<div class="bg-white ht-100vh d-flex flex-column justify-content-between pb-lg-5">

  <div class="text-center p-4">
    <img class="global-logo" src="./assets/images/Global-exploration.png" alt="">
  </div>

  <div class="text-center mx-4">
    <div class="max-wd-allowed">
      <img class="image-size" src="./assets/images/no_internet.png" alt="">
      <p class="text-m mulish-bold mt-5">{{'No internet connection' | translate}}!</p>
      <p class="text-sm mulish-normal">
        {{'Slow or no internet connection. Check your connection or try again' | translate}}
      </p>
    </div>
  </div>

  <div class="mx-4 py-5">
    <div class="max-wd-allowed">
      <button type="button" (click)="reload()" class="btn btn-danger btn-lg btn-block ht-50px mt-3 mulish-bold text-uppercase">{{'Reload page' | translate}}</button>
    </div>
  </div>
</div>
