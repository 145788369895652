import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import totp from 'totp-generator';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with basic auth credentials if available
        // const currentUser = JSON.parse(sessionStorage.getItem('studentData'));
        // if (currentUser) {
        //     request = request.clone({
        //         setHeaders: {
        //             Bearer: `${currentUser.token}`,
        //             'local-timestamp': String(Math.floor((new Date().getTime()/1000)))

        //         }
        //     });
        // }
        let otp = totp('SAJDSJADMEIJCXLZSA');
        request = request.clone({
          setHeaders: {
              // Bearer: 'hsdbcsd@#@$WESfdsnsAw8w434956jsda360ohewq2739812',
              'Otp': otp,
              'local-timestamp': String(Math.floor((new Date().getTime()/1000)))
          }
        });

        return next.handle(request);
    }
}
