import { Component, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './shared/services/loader/loader.service';
import { ConnectionService } from 'ng-connection-service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'global-exploration';
  showLoader: boolean = false;
  loaderStatus: Subscription = new Subscription();
  noInternet = false;
  intenetConnection: Subscription = new Subscription();

  constructor(
    public loaderService: LoaderService,
    private cdr: ChangeDetectorRef,
    private connectionService: ConnectionService,
    private translate: TranslateService
  ) {
    this.intenetConnection = this.connectionService.monitor().subscribe(isConnected => {
      console.log(isConnected);
      if (isConnected) {
        this.noInternet = false;
      } else {
        // this.router.navigate(['/no-internet']);
        this.noInternet = true;
      }
    })
  }

  ngOnInit() {
    // console.log('test');
    this.translate.setDefaultLang('nl');
    this.translate.use('nl');
  }

  ngAfterViewInit() {
    this.loaderStatus = this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    if (this.loaderStatus) {
      this.loaderStatus.unsubscribe();
    }
    if (this.intenetConnection) {
      this.intenetConnection.unsubscribe();
    }
  }
}
