import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-copy-btn',
  templateUrl: './copy-btn.component.html',
  styleUrls: ['./copy-btn.component.scss']
})
export class CopyBtnComponent implements OnInit {

  @Input() content: string;

  constructor() { }

  ngOnInit(): void {
  }

}
