<mat-form-field appearance="fill">
    <mat-label>Select an option</mat-label>
    <mat-select [(value)]="selected">
      <mat-option>None</mat-option>
      <mat-option value="option1">Option 1</mat-option>
      <mat-option value="option2">Option 2</mat-option>
      <mat-option value="option3">Option 3</mat-option>
    </mat-select>
  </mat-form-field>
  
  <p>You selected: {{selected}}</p>