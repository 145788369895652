import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { MaterialModule } from '../other-modules/matmodules/material.module';
import { CopyBtnComponent } from './copy-btn/copy-btn.component';
import { QRCodeModule } from 'angularx-qrcode';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PaymentNoInternetComponent } from '../features/pages/payment-no-internet/payment-no-internet.component';
import { TranslateModule } from '@ngx-translate/core';
import { NumberFormatPipe } from './pipes/number.pipe';
import { SnackbarComponent } from './snackbar/snackbar.component';


@NgModule({
  declarations: [LoaderComponent, CopyBtnComponent, PaymentNoInternetComponent,NumberFormatPipe , SnackbarComponent],
  imports: [
    CommonModule,
    MaterialModule,
    QRCodeModule,
    FormsModule,
    MatCheckboxModule,
    TranslateModule,
    ReactiveFormsModule,
    MatIconModule
  ],
  exports: [
    NumberFormatPipe,
    PaymentNoInternetComponent,
    FormsModule,
    MatCheckboxModule,
    QRCodeModule,
    LoaderComponent,
    CopyBtnComponent,
    ReactiveFormsModule,
    TranslateModule
  ],
  providers:[
    NumberFormatPipe
  ]

})
export class SharedModule { }
