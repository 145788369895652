import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-payment-no-internet',
  templateUrl: './payment-no-internet.component.html',
  styleUrls: ['./payment-no-internet.component.scss']
})
export class PaymentNoInternetComponent implements OnInit {

  referralCode:any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
  ) {

   }

  ngOnInit(): void {
    // this.route.paramMap.subscribe((params:any) => {
    //   console.log(params);
    //   if(params.params.referral_code != undefined){
    //     this.referralCode = params.params.referral_code;
    //   } else{
    //     this.router.navigate(['/referral-code']);
    //   }
    // });
  }

  reload(){
    // this.router.navigate(['/sponsor/'+this.referralCode]);
    // this.router.navigate(['/referral-code']);
    location.reload();
  }

}
